/* src/index.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Styles */
.form-container {
  @apply max-w-md mx-auto p-4 bg-white shadow-md rounded-md mt-10;
}

.form-label-arabic,
.form-label-kurdish {
  @apply block text-left text-gray-700 mb-2;
}

.form-textarea {
  @apply w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500;
}

.form-label-image {
  @apply block text-gray-700 mb-2;
}

.form-input-file {
  @apply block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100;
}
